import React from "react"
import Layout from "@components/layout"
import Container from "@components/layout/container"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import Button from "@components/elements/button"
import styled, { useTheme } from "styled-components"
import Anchor from "@components/elements/anchor"
import theme from "../styles/theme"
import media from "../styles/media"
import Image from "@components/image"
import { graphql } from "gatsby"
import { processImages } from "@utils/process-images"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const LogoContainer = styled.div`
  text-align: center;
  svg {
    max-height: 12rem;
  }
`

const ImageWrapper = styled.div`
  text-align: center;
  .gatsby-image-wrapper {
    border-radius: 0.5rem;
    box-shadow: ${theme.shadow.default};
  }
  ${props =>
    props.reverse &&
    media.md`
  order: 1;
`}
`

ImageWrapper.propTypes = {
  reverse: PropTypes.bool,
}

const YoutubeEmbed = styled.div`
  box-shadow: ${theme.shadow.default};
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
  }

  ${media.md`
  order: 1;
`}
`

const flexGap = { gap: "12rem", gapMd: "8rem", gapSm: "6rem" }

const G2CrowdPage = ({ data }) => {
  const images = processImages(data.images.edges)
  const { shadow } = useTheme()

  return (
    <Layout
      fullWidth
      title={`G2 Crowd Reviews and Rankings`}
      description={`Klipfolio dashboards ranked as marketplace leader by G2 Crowd.`}
      marginless
    >
      <Flex
        {...flexGap}
        padding="8rem 0 6rem"
        margin="0 0 8rem"
        background="#e0f8ff"
      >
        <Container text>
          <Flex gap="3rem">
            <div>
              <LogoContainer>
                <Image
                  height={200}
                  objectFit="scale-down"
                  file={images["f23bd911-54bc-4798-b3d8-8c2e0c462d71"]}
                />
              </LogoContainer>
              <Heading color="#013950" fontSize="4rem" center>
                Klipfolio dashboards ranked as marketplace leader by G2 Crowd
              </Heading>
            </div>
            <Paragraph
              fontSize="1.4rem"
              lineHeight="140%"
              center
              fontWeight="600"
            >
              Reviewers have Klipfolio&apos;s dashboard reporting tool ranked as
              the leader in the &apos;Data Visualization Technology&apos;
              category. Klipfolio is a dashboard app that helps organizations
              understand, track, and visualize KPIs, metrics, and other
              essential business information.
            </Paragraph>
            <Button.Klips margin="0 auto" lineHeight="1" padding="1rem 2.75rem">
              Get Klips Free
            </Button.Klips>
          </Flex>
        </Container>
      </Flex>
      <Flex {...flexGap} margin="0 0 8rem">
        <Container text>
          <Flex gap="2rem" center>
            <Heading as="h2" margin="0 0 2rem">
              Trusted by over 50,000 game changers and their teams
            </Heading>
            <Div>
              <Image file={images["5bfee070-fa2a-443d-acc4-99925b51ba2c"]} />
            </Div>
          </Flex>
        </Container>
        <Container>
          <Flex {...flexGap}>
            <Grid
              columns="2fr 3fr"
              columnsMd="1fr"
              gap="4rem"
              alignItems="flex-start"
            >
              <Flex gap="2rem">
                <Heading as="h2">
                  Bring all your company data into one spot
                </Heading>
                <Paragraph>
                  Bring order to a chaotic data universe using Klipfolio
                  dashboards. Draw data from your web services, database, Excel
                  and Google Sheets to create unified company dashboards.
                </Paragraph>
                <Paragraph>
                  There&apos;s always a way to get your data into Klipfolio. You
                  can even email it to our system using an attachment.
                </Paragraph>
                <div>
                  <Image
                    file={images["8ad820e6-ff59-46e8-bfad-206253c448e8"]}
                  />
                </div>
                <Anchor link="https://www.klipfolio.com/integrations" arrow>
                  Learn more about our integrations
                </Anchor>
              </Flex>
              <Image
                file={images["46b90309-cffb-464a-b2e5-7804e34a9a7d"]}
                radius={0.5}
                borderStyle={{
                  boxShadow: shadow.default,
                }}
              />
            </Grid>
            <Grid
              columns="3fr 2fr"
              columnsMd="1fr"
              gap="4rem"
              alignItems="flex-start"
            >
              <ImageWrapper reverse>
                <Image
                  file={images["848983cc-1469-47c9-bc2a-d30e63bec2c1"]}
                  radius={0.5}
                  borderStyle={{
                    boxShadow: shadow.default,
                  }}
                />
              </ImageWrapper>
              <Flex gap="2rem">
                <Heading as="h2">
                  Deploy reports and dashboards in hours
                </Heading>
                <Paragraph>
                  You can start building your BI reports and dashboards today
                  with Klipfolio. Use the free trial to explore all our features
                  and connect to your data wherever it is.
                </Paragraph>
                <Paragraph>
                  Have a TV and a casting device in your office? You could have
                  a TV dashboard set up before you head home for dinner tonight.
                </Paragraph>
                <Anchor link="https://www.klipfolio.com/gallery" arrow>
                  Check out our gallery of pre-built content
                </Anchor>
              </Flex>
            </Grid>
            <Grid
              columns="2fr 3fr"
              columnsMd="1fr"
              gap="4rem"
              alignItems="flex-start"
            >
              <Flex gap="2rem">
                <Heading as="h2">
                  Count on world-class support to help get you started
                </Heading>
                <Paragraph>
                  No matter what you&apos;re trying to do, we&apos;ve got your
                  back. If you&apos;ve read any reviews, you&apos;ll hear our
                  support team is second to none. Our free online support will
                  help you at every step along the way.
                </Paragraph>
                <Anchor
                  link="https://www.klipfolio.com/dashboard-services"
                  arrow
                >
                  Need help with an urgent project? Check out our dedicated
                  support options!
                </Anchor>
              </Flex>
              <ImageWrapper>
                <Image
                  file={images["712c9be4-1c5e-4d66-b3c1-ba9c8d58410f"]}
                  radius={0.5}
                  borderStyle={{
                    boxShadow: shadow.default,
                  }}
                />
              </ImageWrapper>
            </Grid>
            <Grid
              columns="3fr 2fr"
              columnsMd="1fr"
              gap="4rem"
              alignItems="center"
            >
              <YoutubeEmbed style={{ borderRadius: "1rem" }}>
                <iframe
                  src="https://www.youtube.com/embed/egbfoY_LfnY"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </YoutubeEmbed>
              <Flex gap="2rem">
                <Heading as="h2">
                  Case Study: Versature&apos;s best-in-class dashboards
                </Heading>
                <Paragraph>
                  See how Versature used Klipfolio to get their data out into
                  the organization where it has maximum impact.
                </Paragraph>
              </Flex>
            </Grid>
          </Flex>
        </Container>
      </Flex>
      <KlipsEOPCta />
    </Layout>
  )
}

G2CrowdPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default G2CrowdPage

export const pageQuery = graphql`
  query G2Query {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "5bfee070-fa2a-443d-acc4-99925b51ba2c"
            "f23bd911-54bc-4798-b3d8-8c2e0c462d71"
            "8ad820e6-ff59-46e8-bfad-206253c448e8"
            "46b90309-cffb-464a-b2e5-7804e34a9a7d"
            "848983cc-1469-47c9-bc2a-d30e63bec2c1"
            "712c9be4-1c5e-4d66-b3c1-ba9c8d58410f"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`
